import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import background from "../assets/home/background.png";
import banner from "../assets/home/banner.gif";
import bannerMobile from "../assets/home/banner-mobile.gif";
import trucount from "../assets/home/trucount.png";
import trucountApps from "../assets/home/trucount-apps.png";
import trucarbon from "../assets/home/trucarbon.png";
import trucarbonApps from "../assets/home/trucarbon-apps.png";
import check from "../assets/home/check.png";

import client1 from "../assets/client/WRI.png";
import client4 from "../assets/client/IDCTA.png";
import client5 from "../assets/client/BPN.png";
import client6 from "../assets/client/Bappenas.png";
import client8 from "../assets/client/DCI.jpeg";
import client9 from "../assets/client/Antler.png";

import client10 from "../assets/client/TerraThrive.png";
import client11 from "../assets/client/CarbonEthics.png";
import client12 from "../assets/client/LindungiHutan.png";
import client13 from "../assets/client/RimbaRayaConservation.jpeg";
import client15 from "../assets/client/WiraEnergi.jpeg";
import client16 from "../assets/client/MultiRimbaBorneo.png";

import client17 from "../assets/client/FNPF.png";
import client19 from "../assets/client/chemstar.png";
import client20 from "../assets/client/neste.png";
import client21 from "../assets/client/daemeter.png";
import client22 from "../assets/client/sinergi.png";
import client23 from "../assets/client/akar.png";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const scrollContent = async() => {
        document.querySelector("#content").scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>TruClimate | B2B Climate Solutions Hub</title>
                <meta name="description" content="At TruClimate, we believe in true, actionable solutions for climate change. Join our B2B Climate Solution Hub for accessible decarbonization ..." />
            </Helmet>
            <section id="page-title" className="page-title-dark include-header home-hero-background" style={{backgroundImage:background}}>

                <div className="container clearfix">
                    <div className="col-lg-6">
                        <img src={banner} className="home-banner" alt="banner" id="banner-desktop" />
                        <img src={bannerMobile} className="home-banner" alt="banner" id="banner-mobile" />
                        <div className="heading-block mb-0 home-hero">
                            <h2>Discover <label style={{fontSize:"inherit",color:"#3eb6ae",marginBottom:"0"}}>true</label> and <label style={{fontSize:"inherit",color:"#3eb6ae",marginBottom:"0"}}>actionable</label> climate solutions</h2>
                        </div>
                        <p className="lead">An all-inclusive hub for your organization’s climate action needs</p>
                        <button onClick={() => scrollContent()} className="button button-circle button-small button-border">Learn more</button>
                    </div>
                </div>

            </section>
            <section id="content">
                <div className="content-wrap py-5">

                    <div className="section border-top-0 m-0">
                        <div className="container-fluid center clearfix">

                            <div className="heading-block" style={{marginBottom:"0px"}}>
                                <h2>HOW CAN WE HELP</h2>
                            </div>

                        </div>

                        <div className="container clearfix py-5">

                            <div className="row col-mb-50 py-5">
                                <div className="col-lg-4 d-flex align-items-center" style={{minHeight:"350px"}}>
                                    <img src={trucountApps} alt="TruCount" />
                                </div>

                                <div className="col-lg-8 text-center text-md-start">
                                    <img src={trucount} className="product-logo" alt="TruCount" />

                                    <h4>Climate Action Partner for your Organization</h4>

                                    <ul className="iconlist iconlist-color mb-5 product-detail">
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">Designed to help businesses and organizations track and manage their greenhouse gas emissions, to ultimately make informed decisions to reduce their environmental footprint</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">Allows users to easily monitor and record emissions data</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">Built-in ability to set targets for reduction and create reports to fulfill requirements from regulators, investors, & financial institutions</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">Automated analytics to identify areas for emissions reduction and provide data-driven solutions</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">Real time visualization for organizations to track real progress towards reduction goals</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">User-friendly interface and customizable features for easy management of emissions records</p></li>
                                    </ul>

                                    <Link to={'/trucount'} className="">Discover How it Works <i className="icon-line-arrow-right"></i></Link>

                                </div>
                            </div>

                        </div>
                        
                        <div className="container clearfix py-5">

                            <div className="row col-mb-50 py-5">
                                <div className="col-lg-4 d-flex align-items-center desktop-hide" style={{minHeight:"350px"}}>
                                    <img src={trucarbonApps} alt="TruCarbon" />
                                </div>

                                <div className="col-lg-8 text-center text-md-start">
                                    <img src={trucarbon} className="product-logo" alt="TruCarbon" />

                                    <h4>Carbon Offsets Solutions For All</h4>

                                    <ul className="iconlist iconlist-color mb-5 product-detail">
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">Tech-enabled tools to help Carbon Offsets & Renewable Energy Certificates issuance for Project Developers</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0">Marketplace for Carbon Offsets, RECs, as well as Sustainable Products & Services</p></li>
                                        <li className="home-list mb-3"><img src={check} className="tick-icon rightmargin-sm" alt="."/> <p className="mb-0 home-detail">Carbon Project Financing</p></li>
                                    </ul>

                                    <div className="row">
                                        <div className="col-lg-6 bottommargin">
                                            <div className="heading-block fancy-title border-bottom-0 title-bottom-border">
                                                <h4>Start a <span>Carbon Project</span></h4>
                                            </div>
                                            <div className="col-md-12 py-1"><Link to={'/trucarbon'} className="">Discover How it Works <i className="icon-line-arrow-right"></i></Link></div>
                                            <div className="col-md-12 py-1"><Link to={'/contact'} className="">Check Your Eligibility <i className="icon-line-arrow-right"></i></Link></div>
                                        </div>
                                        
                                        <div className="col-lg-6 bottommargin">
                                            <div className="heading-block fancy-title border-bottom-0 title-bottom-border">
                                                <h4>Find Quality <span>Offsets</span></h4>
                                            </div>
                                            <div className="col-md-12 py-1"><Link to={'/trucarbon'} className="">Discover How it Works <i className="icon-line-arrow-right"></i></Link></div>
                                            <div className="col-md-12 py-1"><Link to={'/contact'} className="">Buy Offsets <i className="icon-line-arrow-right"></i></Link></div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-4 d-flex align-items-center mobile-hide" style={{minHeight:"350px"}}>
                                    <img src={trucarbonApps} alt="TruCarbon" />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>

            
            <section id="content">
                <div className="content-wrap py-0">

                    <div className="section border-top-0 m-0">
                        <div className="container-fluid center clearfix">

                            <div className="heading-block">
                                <h2>CLIENTS & PARTNERS</h2>
                            </div>

                        </div>
                    </div>

                    <div className="container clearfix">
                        <ul className="clients-grid grid-2 grid-sm-3 grid-md-6 bottommargin-lg">
                            <li className="grid-item"><img src={client1} alt="Clients"/></li>
                            <li className="grid-item"><img src={client4} alt="Clients"/></li>
                            <li className="grid-item"><img src={client5} alt="Clients"/></li>
                            <li className="grid-item"><img src={client6} alt="Clients"/></li>
                            <li className="grid-item"><img src={client8} alt="Clients"/></li>
                            <li className="grid-item"><img src={client9} alt="Clients"/></li>
                            <li className="grid-item"><img src={client10} alt="Clients"/></li>
                            <li className="grid-item"><img src={client11} alt="Clients"/></li>
                            <li className="grid-item"><img src={client12} alt="Clients"/></li>
                            <li className="grid-item"><img src={client13} alt="Clients"/></li>
                            <li className="grid-item"><img src={client15} alt="Clients"/></li>
                            <li className="grid-item"><img src={client16} alt="Clients"/></li>
                            <li className="grid-item"><img src={client17} alt="Clients"/></li>
                            <li className="grid-item"><img src={client19} alt="Clients"/></li>
                            <li className="grid-item"><img src={client20} alt="Clients"/></li>
                            <li className="grid-item"><img src={client21} alt="Clients"/></li>
                            <li className="grid-item"><img src={client22} alt="Clients"/></li>
                            <li className="grid-item"><img src={client23} alt="Clients"/></li>
                        </ul>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

export default Home