import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import qs from 'qs'
import axios from 'axios'
import Swal from 'sweetalert2'

const Contact = () => {
  const [linkLinkedin, setLinkLinkedin] = useState([]);
  const [linkInstagram, setLinkInstagram] = useState([]);
  const [linkWhatsapp, setLinkWhatsapp] = useState([]);
  const [linkMail, setLinkMail] = useState([]);
  const [linkMaps, setLinkMaps] = useState([]);

  const [formName, setFormName] = useState([]);
  const [formEmail, setFormEmail] = useState([]);
  const [formPhone, setFormPhone] = useState([]);
  const [formOrganization, setFormOrganization] = useState([]);
  const [formSubject, setFormSubject] = useState([]);
  const [formService, setFormService] = useState('');
  const [formMessage, setFormMessage] = useState([]);

  useEffect(() => {
      setLinkLinkedin("https://linkedin.com/company/truclimate");
      setLinkInstagram("https://instagram.com/truclimate");
      setLinkWhatsapp("https://wa.me/+6281275702780");
      setLinkMail("mailto:contact@truclimate.earth");
      setLinkMaps("https://goo.gl/maps/eoJvXyDoFxXwrVvm6");
      window.scrollTo(0, 0);
  }, []);

  const sendMessage = async() => {
    document.querySelector("#overlay-spinner").style.setProperty('display', 'block');
    // eslint-disable-next-line
    let checkMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
    if(String(formName).trim() === "") {
      Swal.fire({
          title: 'Warning',
          text: 'Please input valid name',
          icon: 'warning',
          confirmButtonColor: '#4c638c',
      })
    }else{
      if(checkMail.test(formEmail)) {
          if(String(formPhone).match('[0-9]{8,}')) {
            if(String(formOrganization).trim() === "") {
              Swal.fire({
                title: 'Warning',
                text: 'Please input valid organization name',
                icon: 'warning',
                confirmButtonColor: '#4c638c',
              })
            }else{
              if(String(formSubject).trim() === "") {
                Swal.fire({
                    title: 'Warning',
                    text: 'Please input valid subject',
                    icon: 'warning',
                    confirmButtonColor: '#4c638c',
                })
              }else{
                if(parseInt(formService) === 0 || String(formService).trim() === "") {
                  Swal.fire({
                    title: 'Warning',
                    text: 'Please select service',
                    icon: 'warning',
                    confirmButtonColor: '#4c638c',
                  })
                }else{
                  if(String(formMessage).trim() === "") {
                      Swal.fire({
                          title: 'Warning',
                          text: 'Please input valid message',
                          icon: 'warning',
                          confirmButtonColor: '#4c638c',
                      })
                  }else{
                    const data = {
                      'contact_name': formName,
                      'contact_email': formEmail,
                      'contact_phone': formPhone,
                      'contact_organization': formOrganization,
                      'contact_subject': formSubject,
                      'contact_service': formService,
                      'contact_message': formMessage,
                    };
                    const options = {
                      method: 'POST',
                      headers: { 'content-type': 'application/x-www-form-urlencoded' },
                      data: qs.stringify(data),
                      url: 'https://api-beta.truclimate.earth/contact/inquiry'
                      //url: 'http://localhost:8080/contact/inquiry'
                    };
                    try {
                      const response = await axios(options)
                      if (response.data.success) {
                        Swal.fire({
                          title: 'Thank you',
                          html: 'Thank you for contacting TruClimate.<br/>Our team will contacting you soon.',
                          icon: 'success',
                          confirmButtonColor: '#4c638c',
                        })
                      }else{
                        Swal.fire({
                          title: 'Warning',
                          html: 'There is problem on sending the message,<br/>please try again later or contact us at<br/>contact@truclimate.earth',
                          icon: 'warning',
                          confirmButtonColor: '#4c638c',
                        })
                      }
                    } catch (err) {
                      Swal.fire({
                        title: 'Warning',
                        html: 'There is problem on sending the message,<br/>please try again later or contact us at<br/>contact@truclimate.earth',
                        icon: 'warning',
                        confirmButtonColor: '#4c638c',
                      })
                    }
                  }
                }
              }
            }
          }else{
              Swal.fire({
                  title: 'Warning',
                  text: 'Please input valid phone number',
                  icon: 'warning',
                  confirmButtonColor: '#4c638c',
              })
          }
      }else{
          Swal.fire({
              title: 'Warning',
              text: 'Please input valid email address',
              icon: 'warning',
              confirmButtonColor: '#4c638c',
          })
      }
    }
    
    document.querySelector("#overlay-spinner").style.setProperty('display', 'none');
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us - TruClimate | B2B Climate Solutions Hub</title>
        <meta name="description" content="Ready to take action on climate change? Contact us at TruClimate and learn how our B2B Climate Solution Hub can help your organization drive decarbonization ..." />
      </Helmet>
      <section id="page-title" className="page-title-parallax page-title-dark include-header" style={{backgroundColor:"#ffffff",backgroundImage:"url('assets/images/product/background.png')",backgroundSize:"contain",paddingBottom:"0"}}>

        <div className="container clearfix topmargin-lg bottommargin-lg">
            <div className="col-lg-6">
                <h1 className="page-header">Contact Us</h1>
                <p className="lead">We'd love to hear from you</p>
            </div>
        </div>

      </section>
      <section id="content">
        <div className="content-wrap">
          <div className="container">

            <div className="row gutter-40 col-mb-80">
              <div className="postcontent col-lg-8">

                <h3>Send us an Email</h3>

                <div className="form-widget">

                  <div className="form-result"></div>

                  <form className="mb-0" id="template-contactform" name="template-contactform" method="post">

                    <div id="overlay-spinner" className="form-process">
                      <div className="css3-spinner">
                        <div className="css3-spinner-scaler"></div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 form-group">
                        <label htmlFor="template-contactform-name">Name</label>
                        <input type="text" id="template-contactform-name" name="template-contactform-name" className="sm-form-control" value={formName} onChange={(e) => setFormName(e.target.value)}/>
                      </div>

                      <div className="col-md-4 form-group">
                        <label htmlFor="template-contactform-email">Email</label>
                        <input type="email" id="template-contactform-email" name="template-contactform-email" className="email sm-form-control" value={formEmail} onChange={(e) => setFormEmail(e.target.value)}/>
                      </div>

                      <div className="col-md-4 form-group">
                        <label htmlFor="template-contactform-phone">Phone</label>
                        <input type="text" id="template-contactform-phone" name="template-contactform-phone" className="sm-form-control" value={formPhone} onChange={(e) => setFormPhone(e.target.value)}/>
                      </div>

                      <div className="w-100 form-group">
                        <label htmlFor="template-contactform-phone">Company / Organization</label>
                        <input type="text" id="template-contactform-organization" name="template-contactform-organization" className="sm-form-control" value={formOrganization} onChange={(e) => setFormOrganization(e.target.value)}/>
                      </div>

                      <div className="col-md-6 form-group">
                        <label htmlFor="template-contactform-subject">Subject</label>
                        <input type="text" id="template-contactform-subject" name="subject" className="sm-form-control" value={formSubject} onChange={(e) => setFormSubject(e.target.value)}/>
                      </div>

                      <div className="col-md-6 form-group">
                        <label htmlFor="template-contactform-service">Services</label>
                        <select id="template-contactform-service" name="template-contactform-service" className="sm-form-control" value={formService} onChange={(e) => setFormService(e.target.value)}>
                          <option value="0">-- Select One --</option>
                          <option value="1">Emissions Accounting Platform</option>
                          <option value="2">Sustainability Reporting</option>
                          <option value="3">Carbon Project Development</option>
                          <option value="4">Carbon Offsets Purchases</option>
                          <option value="5">Request Eligibility Check</option>
                          <option value="6">General Enquiries</option>
                        </select>
                      </div>

                      <div className="w-100"></div>

                      <div className="col-12 form-group">
                        <label htmlFor="template-contactform-message">Message</label>
                        <textarea className="sm-form-control" id="template-contactform-message" name="template-contactform-message" rows="6" cols="30" value={formMessage} onChange={(e) => setFormMessage(e.target.value)}></textarea>
                      </div>

                      <div className="col-12 form-group d-none">
                        <input type="text" id="template-contactform-botcheck" name="template-contactform-botcheck" className="sm-form-control" />
                      </div>

                      <div className="col-12 form-group">
                        <button onClick={() => sendMessage()} className="button button-3d m-0" type="button" id="template-contactform-submit" name="template-contactform-submit">Send Message</button>
                      </div>
                    </div>

                  </form>
                </div>

              </div>

              <div className="sidebar col-lg-4 product-detail">
                {/*eslint-disable-next-line*/}
                <a href={linkMaps} target="_blank"><address>
                  <strong>Address :</strong><br/>
                  Citylofts Sudirman Unit 820<br/>
                  Jl. K.H. Mas Mansyur No. 121<br/>
                  Central Jakarta, Indonesia 10220<br/>
                </address></a>
                <strong>Phone :</strong><br/>(+62) 812-7570-2780<br/><br/>
                <strong>Email :</strong><br/>contact@truclimate.earth

                <div className="widget border-0 pt-0">
							    {/*eslint-disable-next-line*/}
                  <a href={linkLinkedin} target="_blank" className="social-icon si-dark si-linkedin">
                    <i className="icon-linkedin"></i>
                    <i className="icon-linkedin"></i>
                  </a>
							    {/*eslint-disable-next-line*/}
                  <a href={linkInstagram} target="_blank" className="social-icon si-dark si-instagram">
                    <i className="icon-instagram"></i>
                    <i className="icon-instagram"></i>
                  </a>
                  {/*eslint-disable-next-line*/}
                  <a href={linkWhatsapp} target="_blank" className="social-icon si-dark si-whatsapp">
                    <i className="icon-whatsapp"></i>
                    <i className="icon-whatsapp"></i>
                  </a>

                  <a href={linkMail} className="social-icon si-dark si-email3">
                    <i className="icon-email3"></i>
                    <i className="icon-email3"></i>
                  </a>

                </div>

              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact