import { useEffect } from "react";
import { Link } from "react-router-dom";
import trucount from "../assets/home/trucount.png";
import assess from "../assets/trucount/assess.png";
import goal from "../assets/trucount/goal.png";
import measure from "../assets/trucount/measure.png";

const TruCount = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <section id="page-title" className="page-title-parallax page-title-dark include-header" style={{backgroundColor:"#ffffff",backgroundImage:"url('assets/images/product/background.png')",backgroundSize:"contain",paddingBottom:"0"}}>

          <div className="container clearfix topmargin-lg bottommargin-lg">
              <div className="col-lg-6">
                  <img src={trucount} className="product-header" alt="TruCount" />
                  
                  <p className="lead">Climate Action Partner for Your Organizations</p>
              </div>
          </div>

      </section>
      <section id="content">
        <div className="content-wrap py-0">

          <div className="section border-top-0 m-0">
            <div className="container clearfix py-5">

              <div className="row col-mb-50 py-5">
                <div className="col-lg-3 d-flex align-items-center trucount-flow">
                  <img src={assess} alt="TruCount" />
                </div>

                <div className="col-lg-9 text-center text-md-start">
                  <h2 className="trucount-header">Assess Your Readiness</h2>
                  <h4>We'll meet you right where you are</h4>
                  <p className="product-detail">We understand that every company is unique. That's why our onboarding process is beginner-friendly, enabling those without prior knowledge to integrate their data seamlessly into our platform.<br/><br/>
                  During this process, we will work with you to assess your organization's needs and readiness for the decarbonization journey. This information will be used to determine which frameworks and standards are most appropriate for your organization's emission accounting needs.<br/><br/>
                  If needed, experts guidance and consultations will be available to assist you with any queries throughout your journey.</p>
                </div>
              </div>

              <div className="row col-mb-50 py-5">
                <div className="col-lg-3 d-flex align-items-center trucount-flow">
                  <img src={measure} alt="TruCount" />
                </div>

                <div className="col-lg-9 text-center text-md-start">
                  <h2 className="trucount-header">Seamless Emissions Data Management</h2>
                  <h4>Record, monitor & generate emissions report</h4>
                  <p className="product-detail mb-1">Our emission accounting platform is designed to help organizations track, monitor and report their emissions data. The platform is fully compatible with Scope 1, Scope 2, and Scope 3 emissions data, allowing you to easily record and monitor all of your emissions in one place.<br/><br/>
                  TruCount is also designed to be compatible with multiple standards, frameworks and ratings, so you can be sure that you are always up-to-date with the latest reporting requirements.<br/><br/>
                  <b>Reporting standards :</b></p>
                  <div className="row product-detail ml-1">
                    <div className="col-lg-4">
                      <ul className="trucount-detail">
                        <li>GHG Protocol</li>
                        <li>ISO 14064-1</li>
                        <li>Global Reporting Initiative (GRI)</li>
                        {/*<li>POJK 51</li>
                        <li>Sustainability Accounting Standards Board (SASB)</li>*/}
                      </ul>
                    </div>
                    {/*<div className="col-lg-4">
                      <b>Disclosure Frameworks & Target :</b>
                      <ul className="trucount-detail">
                        <li>Science Based Targets (SBTi)</li>
                        <li>Task Force on Climate-Related Financial Disclosures (TCFD)</li>
                        <li>Carbon Disclosure Project (CDP)</li>
                      </ul>
                    </div>
                    <div className="col-lg-4">
                      <b>Ratings :</b>
                      <ul className="trucount-detail">
                        <li>Dow Jones Sustainability Indexes</li>
                        <li>MSCI</li>
                        <li>Sustainalytics</li>
                      </ul>
                    </div>*/}
                  </div>
                </div>
              </div>

              <div className="row col-mb-50 py-5">
                <div className="col-lg-3 d-flex align-items-center trucount-flow">
                  <img src={goal} alt="TruCount" />
                </div>

                <div className="col-lg-9 text-center text-md-start">
                  <h2 className="trucount-header">Set and Achieve Your Goal</h2>
                  <h4>Unlock your company's decarbonization potential with our customized data-driven solutions and roadmap</h4>
                  <p className="product-detail">Our customized solutions are tailored to your business' specific needs, giving you a clear roadmap to achieve your sustainability goals.<br/><br/>
                  Benchmark against industry leaders and track your progress in real-time, with visual representation of your decarbonization strategy.<br/><br/>
                  Connect with our vetted vendors to start implementing your decarbonization plan and see results quickly.<br/><br/>
                  <b>Start shaping a better future for your business today with TruCount powerful decarbonization roadmap tool.</b></p>
                  <br/><br/>
                  <Link to={'/contact'} className="button button-circle button-small button-border">Book a Demo</Link>
                </div>
              </div>

            </div>
          </div>

        </div>
      </section>
    </div>
  )
}

export default TruCount