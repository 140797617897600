import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo-2.png";

const Footer = () => {
    const [linkLinkedin, setLinkLinkedin] = useState([]);
    const [linkInstagram, setLinkInstagram] = useState([]);
    const [linkWhatsapp, setLinkWhatsapp] = useState([]);
    const [linkMail, setLinkMail] = useState([]);
	const [year, setYear] = useState([]);

	useEffect(() => {
        setLinkLinkedin("https://linkedin.com/company/truclimate");
        setLinkInstagram("https://instagram.com/truclimate");
        setLinkWhatsapp("https://wa.me/+6281275702780");
        setLinkMail("mailto:contact@truclimate.earth");
		setYear(new Date().getFullYear());
    }, []);

    return (
        <footer id="footer" className="dark">
			<div id="copyrights">
				<div className="container">

					<div className="row justify-content-between col-mb-30">
						<div className="col-12 col-lg-auto text-center text-lg-start order-last order-lg-first">
							<img src={logo} alt="truclimate" className="mb-4" style={{width:"100px"}}/><br/>
							&copy; {year} Truclimate
						</div>

						<div className="col-12 col-lg-auto text-center text-lg-end">
							<div className="copyrights-menu copyright-links">
								<Link to={"/"}>Home</Link>/
								<Link to={"/contact"}>Contact</Link>/
								<Link to={"/privacy-policy"}>Privacy</Link>/
								<Link to={"/terms-conditions"}>Terms of Use</Link>
							</div>
							{/*eslint-disable-next-line*/}
							<a href={linkLinkedin} target="_blank" className="social-icon inline-block si-borderless mb-0 si-linkedin">
								<i className="icon-linkedin"></i>
								<i className="icon-linkedin"></i>
							</a>
							{/*eslint-disable-next-line*/}
							<a href={linkInstagram} target="_blank" className="social-icon inline-block si-borderless mb-0 si-instagram">
								<i className="icon-instagram"></i>
								<i className="icon-instagram"></i>
							</a>
							{/*eslint-disable-next-line*/}
							<a href={linkWhatsapp} target="_blank" className="social-icon inline-block si-borderless mb-0 si-whatsapp">
								<i className="icon-whatsapp"></i>
								<i className="icon-whatsapp"></i>
							</a>
							<a href={linkMail} className="social-icon inline-block si-borderless mb-0 si-email3">
								<i className="icon-email3"></i>
								<i className="icon-email3"></i>
							</a>
						</div>
					</div>

				</div>
			</div>
		</footer>
    )
}

export default Footer